<script setup>
import Overlay from '@/assets/js/src/components/Overlay.vue'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {usePrefetchData,} from '@/assets/js/src/util/composables/usePrefetchData'
import LoadingDummyCustom from '@/assets/js/src/components/loading/LoadingDummyCustom'
import {computed, useSSRContext,} from "vue"
import {useTranslation,} from "@/assets/js/src/util/composables"
import {useRoute,} from "vue-router"
import {useHeadInfoTitle,} from "@/assets/js/src/layout/useHeadInfo"

const activePinia = getActivePinia()
const {selectedBibles,} = storeToRefs(useBibleStore(activePinia))
const {t,} = useTranslation()
const currentRoute = useRoute()

const {messagesLoaded,} = usePrefetchData({
    loadAsyncMessages: [ 'common', ],
})

useHeadInfoTitle({titleKey: 'async.common.page_not_found.title',})

const bibleLink = computed(() => {
    let bible = selectedBibles.value[0]

    if (messagesLoaded.value) {
        return '/' + encodeURIComponent(bible) + '/' + t('async.common.page_not_found.bible_passage').replace(' ', '')
    }

    return ''
})

const headingMessage = computed(() => {
    if (messagesLoaded.value) {
        let status = currentRoute.params.error?.status
        if (status === 403) {
            return currentRoute.params.error?.status_text
        } else {
            return t('async.common.page_not_found.heading')
        }
    }

    return ''
})

if (import.meta.env.SSR) {
    const ctx = useSSRContext()
    ctx.headers.statusCode = 404
} else {
    let replacePath = decodeURIComponent((currentRoute.fullPath || ''))
    replacePath.startsWith('//') && (replacePath = replacePath.slice(1))
    replacePath && window.history && window.history.replaceState(window.history.state, document.title, replacePath)
}
</script>

<template>
    <overlay class="bs-page-not-found">
        <div class="bs-page-not-found__heading">
            <h1 v-if="messagesLoaded">
                {{ headingMessage }}
            </h1>
            <template v-else>
                <loading-dummy-custom
                    height="48px"
                    width="calc(100% - 48px)"
                    margin="0 auto"
                ></loading-dummy-custom>
                <loading-dummy-custom
                    height="48px"
                    width="60%"
                    margin="16px auto 0 auto"
                ></loading-dummy-custom>
            </template>
        </div>
        <div class="bs-page-not-found__subheading">
            <p v-if="messagesLoaded">
                {{ t('async.common.page_not_found.bible_verse') }} <br>
                <router-link :to="{path: bibleLink}">
                    {{ t('async.common.page_not_found.bible_passage') }}
                </router-link>
            </p>
            <template v-else>
                <loading-dummy-custom
                    height="18px"
                    width="calc(100% - 48px)"
                    margin="0 auto"
                ></loading-dummy-custom>
                <loading-dummy-custom
                    height="18px"
                    width="50px"
                    margin="8px auto 0 auto"
                ></loading-dummy-custom>
            </template>
        </div>
    </overlay>
</template>

<style lang="scss">
    .bs-app .bs-page-not-found {
        .bs-overlay {
            background: map-deep-get($bs-color, bs);
        }

        &__autocomplete-wrapper {
            position: fixed;
            top: 50vh;
            left: 50%;
            width: calc(100% - 48px);
            max-width: 775px;
            margin: 0;
            transform: translate(-50%, -50%);

            @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                max-width: 450px;
                margin: 0;
            }
        }

        &__heading {
            position: fixed;
            top: calc(50vh - 100px);
            left: 50%;
            width: 100%;
            max-width: 775px;
            padding: 0 24px;
            transform: translate(-50%, -50%);

            @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                top: calc(50% - 100px);
                max-width: 450px;
            }

            @media(width <= 400px) {
                top: calc(50% - 112px);
            }

            h1 {
                color: #fff;
                font-weight: 700;
                font-size: 48px;
                text-align: center;

                @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                    font-size: 36px;
                }
            }
        }

        &__subheading {
            position: fixed;
            top: calc(50% + 96px);
            left: 50%;
            width: 100%;
            max-width: 775px;
            padding: 0 24px;
            transform: translate(-50%, -50%);

            @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                max-width: 450px;
            }

            @media(width <= 400px) {
                top: calc(50% + 108px);
            }

            p {
                color: #fff;
                font-weight: 300;
                font-size: 18px;
                text-align: center;

                @media (max-width: #{map-deep-get($bs-xs,breakpointValue)}px) {
                    font-size: 16px;
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }
</style>
